import {h} from 'preact';
import {useState} from "preact/hooks";
import {Offcanvas, NavDropdown } from "react-bootstrap";


const DefMainMenu = () => {
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <header class={`header d-flex align-items-center fixed-top sticked`}>
            <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
                <a href="/" class="logo d-flex align-items-center">
                    <h1>Logis</h1>
                </a>
                <i class="mobile-nav-toggle mobile-nav-show bi bi-list" onClick={handleShow} />
                <nav id="navbar" class="navbar">
                    <Offcanvas show={show} onHide={handleClose} responsive="xl">
                    <ul>
                        <li><a href="/" class="active">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                        <li class="dropdown">
                            <NavDropdown id="nav-dropdown-dark-example" title="Dropdown" menuVariant="dark" >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li>
                        <li><a href="/">Contact</a></li>
                        <li><a class="get-a-quote" href="/">Get a Quote</a></li>
                    </ul>
                    </Offcanvas>
                </nav>
            </div>
        </header>
    )
}

export default DefMainMenu;
