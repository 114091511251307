import { h } from 'preact';
import { Router } from 'preact-router';

import DefMainMenu from './common/main_menu/defMainMenu';
import DefFooter from "./common/footer_menu/defFooter";
// import DefTopMenu from "./common/top_menu/defTopMenu";

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import About from "../routes/about";
import Services from "../routes/services";
import Pricing from "../routes/pricing";

const App = () => (
	<div id="app">
		{/*<DefTopMenu />*/}
		<DefMainMenu />
		<Router>
			<Home path="/" />
			<About path="/about" />
			<Services path="/services" />
			<Pricing path="/pricing" />
		</Router>
		<DefFooter />
	</div>
)

export default App;
