import {h} from 'preact';
import styled from "styled-components";


const Footer = styled.div`
    font-size: 14px;
    background-color: var(--color-secondary);
    padding: 50px 0;
    color: white;
  
    .footer-info .logo {
    line-height: 0;
    margin-bottom: 25px;
    }
    .footer-info .logo img {
    max-height: 40px;
    margin-right: 6px;
    }
    .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    font-family: var(--font-primary);
    }
    .footer-info p {
    font-size: 14px;
    font-family: var(--font-primary);
    }
    .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 10px;
    transition: 0.3s;
    }
    .social-links a:hover {
    color: #fff;
    border-color: #fff;
    }
    
    h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
    }
    .footer-links {
    margin-bottom: 30px;
    }
    .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    }
    .footer-links ul i {
    padding-right: 2px;
    color: rgba(13, 66, 255, 0.8);
    font-size: 12px;
    line-height: 0;
    }
    .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    }
    .footer-links ul li:first-child {
    padding-top: 0;
    }
    .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    }
    .footer-links ul a:hover {
    color: #fff;
    }
    .footer-contact p {
    line-height: 26px;
    }
    
    .copyright {
    text-align: center;
    }
    .credits {
    padding-top: 4px;
    text-align: center;
    font-size: 13px;
    }
    .credits a {
    color: #fff;
    }
    `

const DefFooter = () => {
    return (
        <Footer className="footer">
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                        <a href="#" className="logo d-flex align-items-center">
                            <span>Logis</span>
                        </a>
                        <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita
                            valies
                            darta donna mare fermentum iaculis eu non diam phasellus.</p>
                        <div className="social-links d-flex mt-4">
                            <a href="#" className="twitter"><i className="bi bi-twitter" /></a>
                            <a href="#" className="facebook"><i className="bi bi-facebook" /></a>
                            <a href="#" className="instagram"><i className="bi bi-instagram" /></a>
                            <a href="#" className="linkedin"><i className="bi bi-linkedin" /></a>
                        </div>
                    </div>
                
                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About us</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">Terms of service</a></li>
                            <li><a href="#">Privacy policy</a></li>
                        </ul>
                    </div>
                
                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            <li><a href="#">Web Design</a></li>
                            <li><a href="#">Web Development</a></li>
                            <li><a href="#">Product Management</a></li>
                            <li><a href="#">Marketing</a></li>
                            <li><a href="#">Graphic Design</a></li>
                        </ul>
                    </div>
                
                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>
                            A108 Adam Street <br />
                            New York, NY 535022<br />
                            United States <br /><br />
                            <strong>Phone:</strong> +1 5589 55488 55<br />
                            <strong>Email:</strong> info@example.com<br />
                        </p>
                
                    </div>
            
                </div>
            </div>
        
            <div className="container mt-4">
                <div className="copyright">
                    &copy; Copyright <strong><span>Logis</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div>
            </div>
        </Footer>
    )
}
export default DefFooter
